/* @import url("https://fonts.__SA-googleapis.__SA-com/css2?family=Great+Vibes&family=Outfit:wght@100;200;300;400&family=Playfair+Display:ital,wght@0,500;0,600;0,700;1,400&family=Work+Sans:wght@300;400;500&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.__SA-store-container {
  width: min(100vw,360px);
  margin: auto;
  position: relative;
}
.__SA-store-container h2 {
  text-align: center;
  margin-top: 30px;
  color: white;
  font-size: var(--heading-analytics-font-size);
}
.__SA-day-week-month {
  font-family: "Asistant", sans-serif;
}
.__SA-day-week-month select {
  width: max(max-content, 100px);
  height: max-content;
  position: absolute;
  top: 50px;
  right:5px;
  padding: 5px 10px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
}
.__SA-perform-analytics {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.__SA-sales-banner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 12px;
}
.__SA-sales-banner h3 {
  font-size: 1.1rem;
  color: white;
}
.__SA-sales-banner h4{
  position: relative;
  top: 95px;
  left: 25px;
  color: black;
  z-index: +10;
}
.__SA-total-sales {
  margin-top: 10px;
  border-radius: 10px;
  height: 100px;
  width: 150px;
  border: 3px solid rgb(255, 145, 0);
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
.__SA-growth-total {
  margin: 10px 7px 0px 7px;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}
.__SA-growth-g {
  word-spacing: 1px;
  font-weight: bold;
}
.__SA-percent-p {
  text-align: right;
  color: green;
}
.__SA-store-amount {
  margin-top: 12px;
  text-align: center;
}
.__SA-store-amount h1 {
  font-size: 1.4rem;
}
.__SA-store-image {
  width: max-content;
  margin: auto;
}
.__SA-store-image img {
  height: 250px;
  width: 165px;
  transform: scale(0.7);
  border-radius: 20px;
}
/* @media screen and (min-width: 600px) {
  .__SA-growth-total{
    font-size: 0.5rem !important;
  }
  .__SA-growth-g {
    font-size: 0.5rem;
  }
  .__SA-percent-p {
    font-size: 0.5rem;
  }
} */