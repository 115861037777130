.Front-Page-container{
  width: min(100vw,400px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Front-Page-btn-container{
  width: max-content;
  margin-top: -120px;
  /* margin-bottom: 100px; */
  position: fixed;
  left: 50%;
  transform: translate(-50%, 650%);
}

.front-img{
  width: min(100vw,400px);
  margin: auto;
  display: block;
  height: 100vh;
}

.btn-started {
  text-align: center;
  text-decoration: none;
  width: 210px;
  padding: 12px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 20px;
  opacity: 0.95;
  background-color: #000207;
  color: #eceff6;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 23px;
  outline: none;
  cursor: pointer;
  margin: auto;

}
