.analytics-container {
  width: min(100vw,360px);
  margin: auto;
}
.analytics-title h2 {
  text-align: center;
  padding: 5px;
  margin: 15px 0px 5px 0px ;
  color: white;
  font-size: 1.6rem;
}
.analytics-total {
  border-radius: 10px;
  height: 160px;
  width: min(85vw,360px);
  background-color: white;
  /* border: 3px solid rgb(255, 0, 162); */
  border: 3px solid rgb(153, 0, 255);
  /* border: 3px solid rgb(160, 5, 180); */
  margin: 4px auto 10px auto;
}

.sales-analytics-percent {
  display: block;
  text-align: right;
  margin-top: 10px;
  margin-right: 20px;
  color: green;
  font-size: 20px;
}
.sales-analytics-growth {
  word-spacing: 1px;
  margin-left: 17px;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-top: -25px;
  color: black;
}
.sales-analytics-amount {
  display: block;
  text-align: center;
  margin-top: 15px;
}
.sales-analytics-amount h1 {
  font-size: 40px;
}
.sales-analytics-amount p {
  font-size: 20px;
  font-weight: 600;
  color: brown;
  margin-top: 2px;
  text-transform: uppercase;
}
.date-month {
  margin: 0px 10px 0px 0px ;
  height: 35px;
}
.date-month select {
  text-align: center;
  height: max-content;
  width: max-content;
  padding: 4px 10px;
  margin : 0px 0px 0px 20px ;
  border-radius: 4px;
  float: right;
}
.chart-container{
  transform: scaleX(0.90);
}
.highlights-list{
  width: max-content;
  margin: auto;
  height: 25vh;
  overflow-y: scroll;
}
.highlights-Frame{
  height: 40px;
  margin-bottom: 8px;
}
.highlights-title{
  width: max-content;
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 3px;
  font-size: 1.1rem;
  margin: 20px auto 8px auto;
}

.highlights-list h3{
  width: max-content;
color: rgb(9, 152, 9);
background-color: #2e2e2e;
padding: 5px 80px;
border-radius: 20px;
opacity: 0.5;
margin: 15px auto 30px auto;
font-size: 1.2rem;
}