#Impact-porda{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: +3;
    transition: 1.4s ease-in-out;
}

#Impact-content{
    transition: 1.4s ease-in-out;
    display: flex;
    align-items: center;
    height: 86.5vh;
}
.Impact-container{
    width: max-content;
    margin: auto ;
}
.Impact-quote{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 250px);
}
.Impact-quote p {
    width: 300px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    font-family: Poppins;
    font-size: 0.9rem;
    padding: 20px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}
/* .quote {
    color: #fff;
} */
.Impact-img{
width: 98vw;
}