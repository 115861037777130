.sales-order-summary {
  width: 330px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 0.8rem;
  margin-top: 30px;
  /* justify-content: space-around; */
  
}
.userName{
  padding-left: 20px;
  width: 106px;
}
.sales-order-summary img{
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.Box{
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
}
.active-people {
  width: max-content;
  margin: auto;
}
.active-people h2{
  color: white;
  width: max-content;
  margin: 20px auto;
}


.rotate-0 {
  transition: transform 0.5s ease;
}

.rotate-180 {
  transform: rotate(360deg);
}

.rotate-animation {
  animation: rotateAnimation 0.5s 8 linear;
}


@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-45deg);
  }
  40% {
    transform: rotate(-120deg);
  }
  80% {
    transform: rotate(-270deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.container{
  display: flex;
  align-items: center;
  

}