.Logo{
  position: fixed;
  top: 15px;
  left: 15px;
  transform: scale(0.8);
}
.type-navigation {
  width: 66vw;
  display: flex;
  justify-content: space-around;
  padding: 2px;
  margin: 8px auto 0px;
  font-weight: 500;

}
.type-nav-btn {
  text-decoration: none;
  padding: 4px 8px;
  margin-top: 10px;
  font-size: 17px;
  /* background-color: #8f00ff; */
  /* border: 1px solid #8f00ff; */
  border-radius: 10px;
  cursor: pointer;
  /* color: white; */
  
  
  background-color: white;
  color: black;
}
.type-nav-cart {
  color: white;
  margin-top: 10px;
}
.type-nav-cart.active {
  border: none;
}
.cart-count {
  float: right;
  margin-top: -47px;
  margin-right: 25px;
}
.cart-count img{
  transform: scale(0.6);
}

.cart-cnt {
  color: white;
  float: right;
  /* margin-top: -58px; */
  /* margin-right: 15px; */
  margin: -49px;
}
.type-nav-btn.active {
  /* background-color: white; */
  /* color: black; */

  background-color: #8f00ff;
  color: white;


  /* border: 1.5px solid rgb(255, 0, 187); */
}

.catalog-nav {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  margin-top: 20px;
  font-weight: 500;
}
.catalog-nav-btn {
  text-decoration: none;
  padding: 4px 8px;
  margin-bottom: 10px;
  font-size: 15px;
  /* border: 1px solid #8f00ff; */
  border-radius: 10px;
  cursor: pointer;
  border: 3.5px solid #000;
  height: max-content;

  /* background-color: #8f00ff; */
  /* color: white; */
  
  background-color: white;
  color: black;
  
}
.catalog-nav-btn.active {
  /* background-color: white; */
  /* color: black; */
  
  background-color: #8f00ff;
  color: white;
  
  
  
  /* border: 1.5px solid red; */
  /* border: 3.5px solid rgb(140, 0, 255); */
  /* box-shadow:  inset 0 0 0 3px rgb(106, 0, 255); */
}
