.mycart-container {
  color: white;
  width: 363px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mycart-container .topbar {
  width: 363px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  color: rgb(56, 56, 56);
  text-align: left;
  margin: 20px auto 10px;
  padding: 10px 0px 10px 30px;
}
.mycart-username{
  width: max-content;
  margin: 0px 0px 0px 50px;
}
.mycart-heading .Logo img{
  margin-top: 7px;
    margin-left: 20px;
}



.cart-items {
  display: grid;
  grid-template-columns: 32px 175px 74px 0px 0px;
  column-gap: 10px;
  row-gap: 20px;
  flex-direction: row;
  width: 365px;
  align-items: center;
  margin-top: 5px;
}
.cart-items-data{
  position: relative;
  /* font-size:0.9rem;
  margin:8px 0px 0px 8px; */
}
.cart-items-data .combo{
  position: absolute;
  top: -7px;
left: -20px;
}
.cart-items-data .free{
  position: absolute;
  top: 3px;
  right: 25px;
  border-radius: 50%;
  transform: rotate(-28deg);
}
.cart-items-data p {
  color: #fff;
  text-align: left;
  margin-left: 20px;
  margin-top: -5px;
  font-weight: 550;
}
.cart-items-icon img{
  transform: scale(0.8);
  margin: 0;
}
.cart-items-checked img {
  font-size: 27px;
  margin-left: 12px;
  margin-top: -1px;

  width: 27px;
  height: 29px;
  color: #009d34;
  /* background-color: #8420e7; */
}
.quantity {
  color: white;
  position: relative;
    left: 216px; 
    font-size: 1.1rem;
    font-weight: bold;
}
.increase-decrease-btn {
  display: flex;
  flex-direction: row;
  padding: -10px;
  margin-left: -38px;
  /* background-color: #009d34; */
  margin-top: -15px;
}
.increase-decrease-btn button {
  height: 25px;
  width: 25px;
  border-radius: 15px;
  font-size: 18px;
  background-color: transparent;
  color: white;
  border: 0px solid white;
  margin: 11px;
  cursor: pointer;
  /* background-color: aqua; */
}
.increase-decrease-btn p {
  font-size: 22px;
  margin-top: 13px;
  margin-left: 10px;
  /* background-color: azure; */
}
.increase-decrease-btn img{
  transform: scale(0.5);  margin: -22px;
}
.price-table {
  display: grid;
  grid-template-columns: 4fr auto;
  width: 363px;
  font-size: 0.9rem;
}
.key {
  /* padding: 10px 40px 0px 40px; */
  padding: 5px 0px 0px 16px;
  font-weight: 500;
}
.val {
  float: right;
  padding: 5px 10px 5px 0px;
}
.navigate-action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.navigate-action button {
  /* width: 150px; */
  /* height: 40px; */
  background-color: #8420e7;
  border-radius: 10px;
  border: 2.5px solid black;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  margin: 20px;
  padding: 5px 15px;
  font-size: 1.05rem;
  font-weight: 550;
  /* background:linear-gradient(120deg, rgb(255, 0, 149) 0% , rgb(81, 0, 255) 100% ) ; */
}
