body{
  user-select: none;
}
#timerAction {
  cursor: pointer;
  background-color: transparent;
  border: none;
  /* background-color: #030303; */
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 10px;
  margin-top: 40px;
}
.otp-msg {
  text-align: center;
  padding: 5px;
  /* margin-top: 40px; */
  font-size: 15px;
  color: white;
  line-height: 40px;
}
/* ******************* Top Section Ends ********************  */


/* ******************* OTP Section Starts ********************  */
.inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  text-align: center;
  margin-top: 20px;
}
.inputs .input {
  margin: 10px;
  top: 219px;
  left: 34px;
  width: 65px;
  height: 62px;
  padding: 0px 8px;
  border: 1px solid #353535;
  box-sizing: border-box;
  border-radius: 22px;
  background-color: #f9fcfe;
  color: #1d1d3d;
  font-size: 52px;
  font-family: "Poppins";
  line-height: 67px;
  text-align: center;
  outline: none;
}

.btn-submit {
  width: 150px;
  height: 55px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 20px;
  background-color:rgb(153, 0, 255);
  color: white;
  font-size: 24px;
  font-family: "calibri";
  font-weight: 700;
  line-height: 31px;
  outline: none;
  cursor: pointer;
}
