.startsession-container{
    background-color: #fff;
    width: min(100vw,420px);
    height: 100vh;
    margin: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    }
    .startsession-container img{
        width: calc(inherit + 1px);
        height:auto;
        width: 100vw;
    }
    .startsession-container p{
        text-align: center;
        margin-top: 30px;
    color: #8420e7;
    /* font-family: "Poppins"; */
    font-weight: bold;
    }
    #greeting1{
        font-size: 1rem;
    }
    #greeting2{
        font-size: 0.9rem;
        word-spacing: 2px;
    }
    .timer{
        width: max-content;
        margin: 20px auto 0px auto;
    }
    .timer img{
        height: 30px;
        width: 30px;
        margin: 0px 0px -5px 0px ;
    }
    .timer span{
        display: inline-block;
        width: max-content;
        color: #009d34;
        font-size: 1.6rem;
        font-family: "Poppins";
        font-weight: bold;
        /* text-align: center; */
        margin: -20px 0px 0px 10px ;

    }