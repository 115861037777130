.Container{
    display: grid;
    grid-template-columns: 1fr 1fr;
}


h4{
    margin: 15px 0px 2px 0px;
    color: #fff;
}

select{
    padding: 4px 8px;
    min-width: 120px;
}
select option{
    padding: 6px 8px;
}
button{ width: max-content; margin:20px 45px 0px auto;padding: 5px 8px; color: #fff; border-radius: 4px; border: none;font-size: 17px;
cursor: pointer;}

.ServerJSONField{
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 20px 16px 20px 16px;
}
/* font-family:'Courier New', Courier, monospace !important; */

.JSON{
    font-family: monospace;
}