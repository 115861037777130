/* @import url('https://fonts.googleapis.com/css2?family=Nunito&family=Poppins:wght@300;500&family=Roboto:wght@300&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Nunito:wght@300;400;500&family=Outfit:wght@100;200;300;400&family=Playfair+Display:ital,wght@0,500;0,600;0,700;1,400&family=Work+Sans:wght@300;400;500&display=swap"); */
/* @import url("https://fonts.__SA-googleapis.__SA-com/css2?family=Great+Vibes&family=Outfit:wght@100;200;300;400&family=Playfair+Display:ital,wght@0,500;0,600;0,700;1,400&family=Work+Sans:wght@300;400;500&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: Assistant , Roboto, Helvetica,Arial,sans-serif ;
}
:root{
  --main-bg-color:black;
  --heading-analytics-font-size:1.5rem;
  
}

body{
  /* background-color: black; */
  background-color: var(--main-bg-color);
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  width: min(100vw,400px);
  height: 90vh;
  /* width: min(95vw,400px); */
  margin: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
