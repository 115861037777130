.main-container{
  display: grid;
  height:600px;
  grid-template-columns: 150px 150px;
  color: aliceblue;
  justify-content: center;
  row-gap: 8px;
  column-gap: 40px;
  margin-top: 0.5rem;
  padding: 20px;
  margin-right: 10px;
  background-color: rgb(0, 0, 0);
  overflow-y: scroll;
  scrollbar-base-color: rgb(0, 0, 0);
  
  }
  .catalog-screen-grid-item{
    height:200px;
    width: 160px;
    text-align: center;    
    border-radius: 10px;
    margin-top: 24px;
  }   
  .product-img{
    height: 150px;
    width: 160px;
    border-radius: 10px;
    }

    .speciality-image{
      width: 155px;
      height: 180px;
    }
  .Add-Product-icon{
    width: 28px;
    height: 28px;
    margin-top: 4px;

  }
  /* .catalog-screen-grid-item h6{
    width: max-content; 
    display: inline-block;
    margin-top: -20px;
  } */
  .Add-product-control{
    display: flex;
    width: 160px;
    justify-content: space-between;
  }
  .Add-product-text h5{
    width: 120px;
    text-align: left;
    margin-left: 10px;
  }
  .Add-product-text h6{
    width: 120px;
    text-align: left;
    margin-left: 10px;
  }
.popup-container .add-to-cart-btn .type-nav-btn{
  /* border: none;
    padding: 5px 15px;
    margin-left: 160px; */
    background-color: #8f00ff;
    color: white;
    margin:50px auto -20px auto;
    width:max-content;
    /* margin-left: 150px; */
}
  #popup-parda{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    background: rgba(0, 0, 0, 0.5);

  }
  .Speciality-AddToCart{
    border:none;
    padding:5px 15px;
    margin-top:40px;
    margin-left:180px;
  }