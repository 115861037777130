.greetings {
  text-align: center;
  padding: 5px;
  margin-top: 40px;
  font-size: 40px;
  color: white;
}
.topMsg {
  text-align: center;
  padding: 5px;
  margin-top: 8px;
  font-size: 15px;
  color: white;
}

.phone-input {
  text-align: center;
  margin-top: 70px;
}

.phone-input input[type="number"] {
  width: 295px;
  height: 63px;
  padding: 12px 12px 12px 130px;
  font-size: 20px;
  background-color: #252a34;
  border-radius: 50px;
  color: white;
  box-sizing: border-box;
  border: none;
  outline-style: solid;
  margin: 0px 0px -15px 0px;    
  outline-width: 3px;

}
.phone-input input[type="text"] {
  width: 295px;
  height: 63px;
  padding: 12px 20px;
  font-size: 20px;
  background-color: #252a34;
  border-radius: 50px;
  color: white;
  box-sizing: border-box;
  border: none;
  outline-style: solid;
}
.phone-input img{
  margin: -48px 0px 3px -191px;
}
.msg-box {
  height: 70px;
  margin-top: 30px;
}

.signup-btn1 {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* margin-top: 100px; */
}
.btn-otp {
  width: 180px;
  height: 62px;
  padding: 0px 8px;
  border: 0;
  box-sizing: border-box;
  border-radius: 20px;
  background-color:rgb(153, 0, 255);
  color: white;
  font-size: 24px;
  font-family: "Quicksand";
  font-weight: 700;
  line-height: 31px;
  outline: none;
  cursor: pointer;
}
.logo {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.logo img {
  margin-top: 80px;
  width: 100px;
  height: 100px;
  border-radius: 25px;
}
