* {
  padding: 0;
  margin: 0;
}
.land-container {
  /* height: 220px; */

  /* width: 500px; */
  background-color: rgb(0, 0, 0);

  margin: auto auto;
  font-family: "Asistant", sans-serif;
}
.ownername-img-session {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  font-family: "Nunito", sans-serif;
}
#OwnerImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #fff;
}

.ownername-img-session button {
  width: 156px;
  height: 45px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(3, 3, 3, 0.1);
  margin: 20px 0px 10px 0px;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: "Nunito", sans-serif;
}
.features {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0px 10px;
  justify-content: space-evenly;
}

.analytics-section {
  color: #fff;
  opacity: 1;
  line-height: 50px;
  margin-left: 20px;
  font-size: 0.8rem;
}
.analytics-section h3 {
  font-size: 1.1rem;
}
.features img {
  width: 135px;
  height: 140px;
  border-radius: 15px;
  margin: auto;
}
.coming-soon {
  font-size: 1.1rem;
  position: relative;
  top: 145px;
  color: black;
  z-index: +10;
  background-color: #fff;

  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
}
