/* #popup-parda {
  width: 95vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: +2;
  transition: 0.5s ease-in-out;
  visibility: hidden;
} */
#popup-container {
  position: fixed;
  background-color: #fff;
  bottom: 75px;
  left: 50%;
  z-index: +4;
  transform: translate(-50%, 0);
  transition: 1.5s ease-in;
  width: 330px;
  height: 595px;
  /* height: 0px; */
  padding: 10px;
  /* padding: 0px ; */
  margin-top: clamp(40px, 70%, 90px);
  overflow-y: scroll;
  scrollbar-color: #8f00ff;
  scrollbar-width: 5px;
  /* margin :auto; */
  overflow-x: hidden;
  border-radius: 20px;
}
#popup-container::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
}

#popup-container::-webkit-scrollbar-track {
  background-color: transparent;
  /* width: 7px; */
}
#popup-container::-webkit-scrollbar-thumb {
  background-color: #8f00ff;
  /* width: 7px; */
  border-radius: 3px;
}

.popup-grid-item {
  display: flex;
  background-color: white;
  text-align: center;
  border-radius: 10px;
  height: 145px;
  width: clamp(330px, 50%, 500px);
  /* width: 300px; */
  margin-top: 40px;
}

#popup-cancel-img {
  width: 30px;
  height: 30px;
  float: right;
}
.popup-img-left {
  /* float: left; */
  height: 130px;
  width: 130px;
  border-radius: 10px;
  margin-left: 10px;
}
.popup-img-left img {
  width: 130px;
  height: 130px;
  border-radius: 10px;
}
.popup-img-right {
  /* margin-top: 15px; */
  width: 180px;
  position: relative;
}
.popupText{
  height: 90px;
  overflow-y: scroll;
}

/* .brandLogo img{
  height: 20px;
  width: 60px;
  margin-right: 10px;
} */
.headingAndbrandLogo{
  display: flex;
  flex-direction: row;
}
.price {
  margin-top: -10px;
  position: absolute;
  bottom: 0;
  left: 68%;
  transform: translateX(-57%);
  font-size: 0.9rem;
  width: 150px;
  margin-bottom: 9px;
  font-weight: 550;
}
.real-price {
  text-decoration: line-through;
}
.heading {
  font-size: 15px;
  color: #8f00ff;
  width: 160px;
  margin-left: 15px;
  text-align: center;
  font-weight: 550;
}
.price-off {
  color: #009d34;
}
/* h5{
  font-size:15px;
  color: #8F00FF;
 } */
.rating-star {
  color: #009d34;
  font-size: 20px;
}
.desc {
  font-size: 13px;
  margin-top: 10px;
  text-align: left;
  width: 160px;
  /* word-wrap:break-word; */
  margin-left: 20px;
  font-weight: 550;
}

.plus-icon {
  float: right;
  margin-right: 143px;
  margin-top: -51px;
  height: 45px;
  width: 43px;
  font-size: 0.65rem;
  border-radius: 24px;
  background-color: #fff;
  color: #fff;
  border: 0px solid #fff;
  cursor: pointer;
  z-index: +2;
  position: relative;
}
